// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("/opt/build/repo/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-loesung-js": () => import("/opt/build/repo/src/pages/loesung.js" /* webpackChunkName: "component---src-pages-loesung-js" */),
  "component---src-pages-produkte-js": () => import("/opt/build/repo/src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-produkte-biokohle-js": () => import("/opt/build/repo/src/pages/produkte/biokohle.js" /* webpackChunkName: "component---src-pages-produkte-biokohle-js" */),
  "component---src-pages-produkte-duengemittelsubstrat-js": () => import("/opt/build/repo/src/pages/produkte/duengemittelsubstrat.js" /* webpackChunkName: "component---src-pages-produkte-duengemittelsubstrat-js" */),
  "component---src-pages-ueber-uns-js": () => import("/opt/build/repo/src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-unser-anspruch-js": () => import("/opt/build/repo/src/pages/unser-anspruch.js" /* webpackChunkName: "component---src-pages-unser-anspruch-js" */),
  "component---src-pages-verfahren-js": () => import("/opt/build/repo/src/pages/verfahren.js" /* webpackChunkName: "component---src-pages-verfahren-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

